import React from 'react'

const appflow = () => {
  return (
    <section className='rw_aboutus' style={{overflowX: "hidden"}}>
    <div className='rw_visionsec py-5'>
                <div className='container'>
                    <div className='row'>
                    
                        <div className='col-md-12' >
                        <h5 className='rw_knowabout'>Overview of App Flow</h5>
                       <center> <iframe width="70%" height="315" src="https://www.youtube.com/embed/j85GkHxy9O4?si=7E_S-Ib1fZz0X0sC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></center>
                        <hr/>
                        <h5 className='rw_knowabout'>1. New User Registration</h5>
                            <img src='./../images/flow/1.png' className='img-fluid rounded' ></img>
                            <h5 className='rw_knowabout'>2. Login</h5>
                            <img src='./../images/flow/2.jpg' className='img-fluid rounded' ></img>
                            <h5 className='rw_knowabout'>3. Menu</h5>
                            <h5 className='rw_knowabout'>4. Integrate Routeway With Shopify <a target='_blank' href='https://www.youtube.com/watch?v=2hqnP8znZ8U'>How to create custom app on shopify and configure on routeway</a></h5>
                            <img src='./../images/flow/8.jpg' className='img-fluid rounded' ></img>
                            <img src='./../images/flow/3.jpg' className='img-fluid rounded' ></img>
                            <h5 className='rw_knowabout'>5. Product</h5>
                            <img src='./../images/flow/4.jpg' className='img-fluid rounded' ></img>
                            <h5 className='rw_knowabout'>6. Push Procut on Portal</h5>
                            <img src='./../images/flow/10.jpg' className='img-fluid rounded' ></img>
                            <h5 className='rw_knowabout'>7.Confirm Order</h5>
                            <img src='./../images/flow/6.jpg' className='img-fluid rounded' ></img>
                            <h5 className='rw_knowabout'>8.NDR</h5>
                            <img src='./../images/flow/7.jpg' className='img-fluid rounded' ></img>
                            
                            <h5 className='rw_knowabout'>9.Account Status</h5>
                            <img src='./../images/flow/9.jpg' className='img-fluid rounded' ></img>
                        </div>
                    </div>
                </div>
            </div>
   </section>
  )
}

export default appflow