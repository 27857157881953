import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import '../Css/footer.css'
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

export default function Progresss() {
    
  return (
    <div id="progress">
        <span id="progressvalue"> <img src='./images/favicos.ico' style={{width:"50%"}}></img></span>
    </div>
  )
}
