import React, { useEffect } from 'react'
import '../Css/aboutus.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,faBullseye,faFilePen } from '@fortawesome/free-solid-svg-icons'
import { faProductHunt } from '@fortawesome/free-brands-svg-icons'
import { faArrowUpRightDots } from '@fortawesome/free-solid-svg-icons'
import { faPercent } from '@fortawesome/free-solid-svg-icons'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'

export default function Aboutus() {

    const {pathanme} = useLocation();
     useEffect(() => {
      window.scrollTo(0,0)
     
     },[pathanme])
   
    
  return (
   <section className='rw_aboutus' style={{overflowX: "hidden"}}>
    <img src='./images/banner.jpg' className='img-fluid'></img>
    <div className='rw_visionsec py-5'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-md-6' >
                        <h5 className='rw_knowabout'>KNOW ABOUT ROUTEWAY</h5>
                        <p className='mt-3 rw_knowaboutpara'>We are the among leading prominent trading company providing e-platform to model your Business plan for the Growth of the Company. People can sell the goods and create a link among the people to profit their Business plan to create Entrepreneur skills to profit themselves. Not only we can sell goods but we can also supply the goods on our Business model to profit our Company RouteWay.RouteWay provides you e-platform for Your Business Model to map our Company Goal. We not only provides platform but create opportunity to sell goods on your customized margin. Margin rate your sell of goods and make you most profitable person among other people. You can create your opportunity to profit RouteWay as well as yourselves.</p>
                    </div>
                        <div className='col-md-6' >
                            <img src='./images/vision.jpeg' className='img-fluid rounded' ></img>
                        </div>
                    </div>
                </div>

                <div className='container mt-4'>
                    <div className='row align-items-center justify-content-center'>
                    <div className='col-12 mt-3 mb-3'>
                        <img src='./images/missionrouteway.jpg' className='img-fluid '></img>
                    </div>
                        <div className='col-md-6'>
                            <h5 className='rw_vivionhead'>Our Vision <FontAwesomeIcon icon={faEye}></FontAwesomeIcon></h5>
                            <p className='rw_paravivion mt-3'>It maps your Business Goal to Our Company. You create Your Own Profit and weprovide you Platform to rate your Profit to secured margin. We are the trading Company also cares Your Presence in the Trading Arena. You and We are on the same Line of The business MODEL.</p>

                            <h5 className='rw_vivionhead mt-5'>Our Mission <FontAwesomeIcon icon={faBullseye}></FontAwesomeIcon></h5>
                            <p className='rw_paravivion mt-3'>We create opportunity so that you map your business growth to our Company RouteWay.RouteWay the name of ultimate goal of the people so that they can profit themselves. You create your Profit and we make you more profitable on our trading Platform. We are not alone we create the path of Success with our Key Account Manager and other teammates so that you can ride on your Path of Success. RouteWay is a subset of your Goal. RouteWay the ultimate Presence of World…</p>
                        </div>
                        <div className='col-md-6'>
                            <img src='./images/mision.png' className='img-fluid'></img>
                        </div>

                        <div className='col-md-6 mt-4'>
                            <img src='./images/3d2.png' className='img-fluid w-75'></img>
                        </div>

                        <div className='col-md-6 mt-4'>
                           <h5 className='rw_howitworks'>HOW IT WORKS ?</h5>
                           <p className='mt-3 rw_paras'>Here are the 5 working steps of success.</p>
                           <ul className='mt-3 rw_workingstepul'>
                            <li>
                             <h5 className='mb-3'><FontAwesomeIcon icon={faFilePen} className="me-2 rw_cionsworks"></FontAwesomeIcon> Register</h5>
                                <p>Opening account on Routeway is easier than opening a can of soda and it’s FREE! Just integrate your shopify store with our app and you are ready to make money.</p>
                            </li>

                            <li>
                                <h5 className='mb-3'> <FontAwesomeIcon icon={faProductHunt} className="me-2 rw_cionsworks"></FontAwesomeIcon> Product Listing</h5>
                                <p>Add products from our amazing and well maintained catalog to your store in just a few clicks and sync them with our panel!</p>
                            </li>

                            <li>
                                <h5 className='mb-3'><FontAwesomeIcon icon={faArrowUpRightDots} className="me-2 rw_cionsworks"></FontAwesomeIcon> Margin</h5>
                                <p>You can get profit by adding your margin on each product</p>
                            </li>

                            <li>
                                <h5 className='mb-3'> <FontAwesomeIcon icon={faChartPie} className="me-2 rw_cionsworks"></FontAwesomeIcon> Marketing</h5>
                                <p>All over India you can sell your product through Facebook/Instagram and Google also Routeway team will help you.</p>
                            </li>

                            <li>
                                <h5 className='mb-3'> <FontAwesomeIcon icon={faPercent} className="me-2 rw_cionsworks"></FontAwesomeIcon> Profit</h5>
                                <p>We deliver the product and collect cod .and your profit margin is directly deposite in your bank account .</p>
                            </li>
                           </ul>
                        </div>
                    </div>
                </div>
            </div>
   </section>
  )
}
