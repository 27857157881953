import React from 'react'
import '../../Css/aboutus.css'
const privacy = () => {
    return (
        <section className='rw_aboutus' style={{ overflowX: "hidden" }}>

            <div className='rw_visionsec py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <center>
                                <h2 className='rw_ppheading'>Privacy Policy</h2>
                            </center>
                            <p className='rw_ppcontent'><b>Effective date: Mar 15, 2023<br></br>
                            </b>
                                Routeway Trading Private Limited, a company registered under the Companies Act, 2013 having its registered office at C/O RANI DEVI, KHATA NO 154, PLOT NO 756 R K PURAM, NR CRISH HOSPITAL DANAPUR Patna BR 801503 IN, operates this platform with brand name RouteWay (hereinafter referred as “RouteWay”, “We”, “Us” or “Our”).
                                <br />
                                This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.<br />

                                We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://www.routeway.in</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12' >
                            <h5 className='rw_ppheading1'>Definitions</h5>
                            <p className='mt-3 rw_ppcontent'>
                                <ol>
                                    <li><b>Service</b> is the https://www.routeway.in website operated by Routeway Trading Private Limited</li>
                                    <li><b>Personal Data </b>means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</li>
                                    <li><b>Usage Data</b> is data collected automatically either generated by the use of the Service (registration on portal).</li>
                                    <li><b>Cookies </b>are small files stored on your device (computer or mobile device).</li>
                                    <li><b>Data Subject </b>is any living individual who is using our Service and is the subject of Personal Data.</li>
                                </ol>
                            </p>
                        </div>
                        <div className='col-md-12' >
                            <h5 className='rw_ppheading1'>General</h5>
                            <p className='mt-3 rw_ppcontent'>RouteWay does not sell, share or rent your personal information to any 3rd party. Your phone number and email might be used to share internal updates regarding Swift or its partners’ products. Any emails and/or SMS sent by RouteWay will only be in connection with the provision of agreed services & products and this Privacy Policy. Periodically, we may reveal general statistical information about Routeay & its users, such as number of visitors, number and type of goods and services purchased, etc. without disclosing any personal. We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure.</p>
                        </div>
                        <div className='col-md-12' >
                            <h5 className='rw_ppheading1'>Information Collection and Use</h5>
                            <h5 className='rw_ppheading2'>Personal Data</h5>
                            <p className='mt-3 rw_ppcontent'>
                                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to"
                                <ul>
                                    <li>Email address</li>
                                    <li>First name and last name</li>
                                    <li>Business Name</li>
                                    <li>Address, State, Province, ZIP/Postal code, City</li>
                                    <li>Mobile No.</li>
                                    <li>Cookies and Usage Data</li>
                                </ul>
                               
                            </p>
                        </div>
                        <div className='col-md-12' >
                            <h5 className='rw_ppheading2'>Use of Personal Information</h5>
                            <p className='mt-3 rw_ppcontent'>We use personal information to provide you with services & products you explicitly requested for, to resolve disputes, troubleshoot concerns, help promote safe services, collect money, measure consumer interest in our services, inform you about offers, products, services, updates, customize your experience, detect & protect us against error, fraud and other criminal activity, enforce our terms and conditions, etc. We also use your contact information to send you offers based on your previous orders and interests. We may occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information (like zip code, age, gender, etc.). We use this data to customize your experience at RouteWay, providing you with content that we think you might be interested in and to display content according to your preferences. The information we collect with cookies is not sold, rented, or shared with any third parties, other than for internal Platform development and maintenance.</p>
                        </div>
                        <div className='col-md-12' >
                            <h5 className='rw_ppheading1'>Contact Us</h5>
                            <p className='mt-3 rw_ppcontent'>If you have any questions or comments or wish to exercise your rights under applicable legislation, please contact our grievance officer/privacy team by sending a email to office@routeway.in</p>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default privacy;