import React,{useState} from 'react'
import '../Css/contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import Swal from 'sweetalert2'

export default function Modal() {
  


  
  const url = "https://app.routeway.in/api/global/enquiry"
  const [data,setdata] = useState({
    name : "",
    email : "",
    number : "",
    query : "",
    iduser : ""
  })

  function handle(e){
  const newdata = {...data}
  newdata[e.target.id] = e.target.value;
  setdata(newdata);


  }

  const submit = (e) => {
    e.preventDefault();

   Axios.post(url,{
      Name : data.name,
      Email : data.email,
      Mobile : parseInt(data.number),
      Query : data.message,
   
    }).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Thankyou',
        text: 'Thankyou, our concern team will conatct you on your mobile or email',
        
      })
      setdata({name : '', email : '', number : '', query : ''})

    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err,
        
       
      })
    })
 
    
  }
  return (
    <div className='modlasec'>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5"  id="exampleModalLabel" style={{color : "#16499c", textTransform : "uppercase", fontSize: "18px", fontwi
        : "700"}}>Connect with us</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className='container'>
    <div className='row'>
        <div className='col-12 mt-2'>
        <h5 className="rw_homehjeaderfirst">We bring solutions to make a <span className="rw_homehjeaderfirstspan">e-commerce Business</span></h5>
        </div>
    </div>
        <div className='row bpr_conatcusfirstrow'>
            <div className='col-md-9 col-12 bpr_conatcusci' id='bpr_getaquote'>
            <h5 className='bpr_contactuspageh5'> Contact us <FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon></h5>
             <div className='bpr_formcard'>
              <form onSubmit={(e) => submit(e)}>
              <div className='bpr_formconatcuscon'>
                <input type= "text"  required onChange={(e) => handle(e)} id = "name" value={data.name} placeholder='Your Full Name'  className='bpr_conatctuspageinputs' ></input>
               
                <input type="number"  maxLength={10} required onChange={(e) => handle(e)} id = "number" value={data.number}  placeholder='Contact number' className='bpr_conatctuspageinputs' ></input>
                <input type="email" required onChange={(e) => handle(e)} id = "email" value={data.email}   placeholder='Enter Your Email' className='bpr_conatctuspageinputs' ></input>
                <textarea required onChange={(e) => handle(e)} id = "message" value={data.message}  rows="3" cols="30" placeholder='Message' className='bpr_conatctuspageinputs'>
                    
                    </textarea>
                    <button type='submit' className='bpr_footerbtnsub6'>  Submit <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon></button>
                </div>
              </form>
             </div>
            </div>

            <div className=' col-md-3 bpr_conatusimagesec '>
                    <img src='./images/conatct.png' className='bpr_contatussimges '></img>
                </div>

               
        </div>
    </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
