import { faBank, faEnvelope, faGlobe, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import '../Css/singup.css'
import Aos from 'aos'
import 'aos/dist/aos'
import 'aos/dist/aos.css'

export default function Singup() {
  const {pathnaame} = useLocation();

  useEffect(() => {
    Aos.init({
      duration : 1500,
    })
    window.scrollTo(0,0);
    document.title = "Routeway Sing-up"
  },[pathnaame])
  return (
    <section className='Singuppage'>
      <div className='container' data-aos= "fade-down">
        <div className='row align-items-center justify-content-center'>
          <div className='col-md-6 mt-3 mt-md-0 '>
            <img src='./images/logo.png' className='rw_singuplogo'></img>
            <h4 className='rw_singuphead'>From factory to customer doorstep, we bring the world to you</h4>
            <h6 className='rw_singupheadersec'>let us handle the inventoy and shipping, so you can focus on growing your business and maximizing profit</h6>
           <button className='rw_singupbtn mt-3 '>Join us</button>
          </div>


          
          <div className='col-md-6 mt-3 mt-md-0'>
         <div className='fomrsec'>
         <h4 className='text-center text-white mb-3'>Create An Account <FontAwesomeIcon icon={faUser}></FontAwesomeIcon></h4>
         <div className='form'>
              <form>
              <div className='mb-3'>
              <label className='lablename' id='name'> Your Name <FontAwesomeIcon icon={faUser}></FontAwesomeIcon></label>
                <input type="text" required id='name' className='rw_signupinputs'></input>
              </div>
              <div className='mb-3'>
              <label className='lablename' id='name'> Email address <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></label>
                <input type="email" required id='name' className='rw_signupinputs'></input>
              </div>
              <div className='mb-3'>
              <label className='lablename' id='name'>Conatct number <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon></label>
                <input type="number" required id='name' className='rw_signupinputs'></input>
              </div>
              <div className='mb-3'>
              <label className='lablename' id='name'>business name  <FontAwesomeIcon icon={faBank}></FontAwesomeIcon></label>
                <input type="text" required id='name' className='rw_signupinputs'></input>
              </div>
              <div className='mb-3'>
              <label className='lablename' id='name'>website name (if any) <FontAwesomeIcon icon={faGlobe}></FontAwesomeIcon></label>
                <input type="text"  id='name' className='rw_signupinputs'></input>
              </div>
              <input type='submit' className='rw_sinuppagesubmit'></input>
              </form>
             <p className='rw_formsinguplast text-center text-white mt-2 mb-2'>Alredy have an account ?  <a href='https://app.routeway.in/' target={'_blank'} style={{color : "#FFC045"}}>Log-in</a></p>
            </div>
         </div>
          </div>

        </div>
      </div>
     
    </section>
  )
}
