import React from 'react'
import '../Css/services.css'
import ReactReadMoreReadLess from "react-read-more-read-less";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
export default function Services() {
    return (
        <div className='rw_services'>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <h5 className='rw_con3fisrthead'>WHY CHOOSE US ?</h5>
                        <p className='mt-3 rw_con3fisrthead2'>The service we provide is specially tailored to meet the needs of your business.</p>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img9.png" className="card-img-top rw-cardimgmain" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">e-Platform</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    We are providing e-platform for prople trade market.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img1.png" className="card-img-top rw-cardimgmain" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Resource Person</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    We have our Key Account Manager to monitor every activity of Business plan
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img2.png" className="card-img-top rw-cardimgmain" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Trading Index</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    We provides dashboard for customer trading index.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img6.png" className="card-img-top rw-cardimgmain" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Profit Matrix</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    we can project your margin to create profit yourselves.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img3.png" className="card-img-top rw-cardimgmain mt-2" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Logistics Management</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    We provides free shipping for every sold items.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img4.png" className="card-img-top rw-cardimgmain mt-2" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Hassle Management</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    Every information will be on your finger tips.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img8.png" className="card-img-top rw-cardimgmain mt-2" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Top–Selling Products</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    We sell prominent products like Titan Products etc.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="card rw-servicescards"  data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img5.png" className="card-img-top rw-cardimgmain mt-2" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Zero Inv-Management</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    Dashoard are there for every updates of information.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4' >
                        <div className="card rw-servicescards" data-aos="zoom-in-right">
                          <div className='rw_serviceim'>
                          <FontAwesomeIcon icon={faQuoteRight} className="rw_cardicons"></FontAwesomeIcon>
                          <img src="./images/services/img7.png" className="card-img-top rw-cardimgmain mt-2" alt="..." />
                          </div>
                            <div className="card-body">
                                <h5 className="card-title rw_cardtitle">Real time Updates</h5>
                                <div className="rw_contentcard">
                                    <ReactReadMoreReadLess charLimit={60}>
                                    We provides every processing updates.
                                    </ReactReadMoreReadLess>
                                </div>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </div>
                    </div>

                 <div className='col-12 mt-2 mb-2'>
                    <img src='./images/banner2.jpg' className='img-fluid' data-aos="fade-down"></img>
                 </div>

                </div>
            </div>
        </div>
    )
}
