
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'
import '../node_modules/bootstrap/dist/js/bootstrap.esm'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import Hedar from './Components/Hedar';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Aboutus from './Components/Aboutus';
import Ecom from './Components/Ecom';
import Hamangement from './Components/Hamangement';
import Lmanagement from './Components/Lmanagement';
import Profitmatrix from './Components/Profitmatrix';
import Realtimeupdate from './Components/Realtimeupdate';
import Resourceperson from './Components/Resourceperson';
import Tps from './Components/Tps';
import Tradingindex from './Components/Tradingindex';
import Singup from './Components/Singup';
import Conatctus from './Components/Conatctus';
import Modal from './Components/Modal';
import Progresss from './Components/Progresss';
import Privacy  from './Components/policies/privacy.jsx';
import Appflow  from './Components/policies/appflow.jsx';


function App() {

  
  
  return (
    <>
      <BrowserRouter>
      <Modal/>
    
        <Hedar loginpath = {'https://app.routeway.in/'}/>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/Aboutus' element= {<Aboutus/>}></Route>
          <Route path='/e-platform' element={<Ecom/>}></Route>
          <Route path='/Hasselmanagement' element={<Hamangement/>}></Route>
          <Route path='/Lagisticmanagement' element={<Lmanagement/>}></Route>
          <Route path='/Profitmatrix' element={<Profitmatrix/>}></Route>
          <Route path='/Realtimeupdates' element={<Realtimeupdate/>}></Route>
          <Route path='/Resourceperson' element={<Resourceperson/>}></Route>
          <Route path='/Topsellingproducts' element={<Tps/>}></Route>
          <Route path='/Tradingindex' element={<Tradingindex/>}></Route>
          <Route path='/Singup' element={<Singup/>}></Route>
          <Route path='/Conatact-us' element={<Conatctus/>}></Route>
          <Route path='/Policy' element={<Privacy/>}></Route>
          <Route path='/Flow' element={<Appflow/>}></Route>
          <Route ></Route>
        </Routes>
        <Footer/>
        <Progresss/>
      </BrowserRouter>
      </>
  );
}

export default App;
