import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faAddressCard, faBank, faBuilding, faCopyright, faEnvelope, faFilePen, faHandshake, faLeaf, faLink, faLocationDot, faLock, faPaperPlane, faPhone, faSeedling } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useState } from 'react'
import { Await, NavLink } from 'react-router-dom'
import '../Css/footer.css'
import Axios from 'axios';
import Swal from 'sweetalert2'

export default function Footer() {

  const url = "https://app.routeway.in/api/global/enquiry"
  const [data,setdata] = useState({
    name : "",
    email : "",
    number : "",
    query : "",
    iduser : ""
  })

  function handle(e){
  const newdata = {...data}
  newdata[e.target.id] = e.target.value;
  setdata(newdata);


  }

  const submit = (e) => {
    e.preventDefault();
   Axios.post(url,{
    Name : data.name,
    Email : data.email,
    Mobile : parseInt(data.number),
    Query : data.message,
    }).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Thankyou',
        text: 'Thankyou, our concern team will conatct you on your mobile or email',
       
      })
      setdata({name : '', email : '', number : '', query :''})
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err,
       
      })
    })
    
    
  }
  return (
   <footer>
    <div className='rwmainfootercon' style={{overflowX : "hidden"}}>
        <div className='container' data-aos = "fade-right">
            <div className='row'>
              <div className='col-xl-3 col-md-6 mb-2'>
                <img src='./images/logo.png' className='rw_logofooter rounded'></img>

               
   <p className='mt-3 rw_footerinfopara'><FontAwesomeIcon icon={faCopyright}></FontAwesomeIcon> 2022 Routeway.</p>

   <ul className='rw_footericonsul mt-4'>
                    <li><a href='https://www.facebook.com/RouteWayindia' target={'_blank'}><FontAwesomeIcon icon={faFacebook} className="rw_footericons"></FontAwesomeIcon></a></li>
                    <li><a href={{JavaScript:void(0)}} ><FontAwesomeIcon icon={faTwitter} className="rw_footericons"></FontAwesomeIcon></a></li>
                    <li><a href='https://www.instagram.com/routeway_/' target={'_blank'}><FontAwesomeIcon icon={faInstagram}  className="rw_footericons2"></FontAwesomeIcon></a></li>
                    <li><a href='https://api.whatsapp.com/send?phone=9185444 04007' target={'_blank'}><FontAwesomeIcon icon={faWhatsapp}  className="rw_footericons2"></FontAwesomeIcon></a></li>
                    <li></li>
                </ul>
                <p className='mt-3'>
                <a href="mailto:info@routeway.in" className=' rw_footerinfopara3'><FontAwesomeIcon icon={faEnvelope} className="me-2"></FontAwesomeIcon> info@routeway.in</a>
                </p>
                <p className='mt-3 rw_footerinfopara'>Routeway assists enterprises and E-commerce sellers in discovering infinite trending products across different categories from the world's most reliable suppliers</p>
              
              </div>

              <div className='col-xl-3 col-md-6 mb-2'>
                <h5 className='rw_footerhead'>Get in Touch <FontAwesomeIcon icon={faBank}></FontAwesomeIcon> </h5> <hr className='fw_hr'></hr>
                <p className='mt-3 rw_footerinfopara2'><FontAwesomeIcon icon={faLocationDot} className="rw_loactiondot"></FontAwesomeIcon>
                 RouteWay. 1018 10th floor, Galaxy blue sapphire plaza, Greater noida west ,Uttar pradesh ,201009 <hr></hr>
                 302, 3rd Floor, Gopi Tower, Saguna More, Danapur Can'tt, Patna, Bihar-801503 <hr></hr>
                 <a href="mailto:info@routeway.in" className=' rw_footerinfopara3'><FontAwesomeIcon icon={faEnvelope} className="me-2"></FontAwesomeIcon> info@routeway.in</a>
                 <br></br>
                 <a href="telto:06124063228" className=' rw_footerinfopara3'><FontAwesomeIcon icon={faPhone} className="me-2"></FontAwesomeIcon> 0612-4063228 (Mon-Fri[10AM-5:30PM]</a>
                </p>   


                {/* <div className='mt-3'>
                <div class="mapouter"><div class="gmap_canvas"><iframe width="auto" height="auto" id="gmap_canvas" src="https://maps.google.com/maps?q=BPR Network India Pvt. Ltd&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                </div> */}
            
              </div>

              <div className='col-xl-3 col-md-6 mb-2'>
              <h5 className='rw_footerhead'>Quick Links <FontAwesomeIcon icon={faLink}></FontAwesomeIcon> </h5> <hr className='fw_hr'></hr>
              <ul className='rw_quicklinks'>
                <li><NavLink to='/Aboutus' className='rw_quicklinks2'><FontAwesomeIcon icon={faAddressCard} className='me-1'></FontAwesomeIcon> About Us</NavLink></li>
                <li><NavLink to='/Conatact-us' className='rw_quicklinks2'><FontAwesomeIcon icon={faPhone} className=''></FontAwesomeIcon> Contact Us</NavLink></li>
                {/* <li><NavLink to='' className='rw_quicklinks2'><FontAwesomeIcon icon={faFilePen} className=''></FontAwesomeIcon> Term and condition</NavLink></li>
                <li><NavLink to='' className='rw_quicklinks2'><FontAwesomeIcon icon={faHandshake}  className=''></FontAwesomeIcon> Refund Policy</NavLink></li>
                <li><NavLink to='' className='rw_quicklinks2'><FontAwesomeIcon icon={faLock}></FontAwesomeIcon> Privacy Policy</NavLink></li> */}
              </ul>
              </div>

              <div className='col-xl-3 col-md-6 mb-2'>
              <h5 className='rw_footerhead'> For Any Enquiry </h5> <hr className='fw_hr'></hr>
              <div className='rw_gooterformcon'>
                <form onSubmit={(e) => submit(e)}>
                    <input type="text"  required onChange={(e) => handle(e)} id = "name" value={data.name} placeholder=" Your Name" className='rw_footerinputs'></input><br></br>
                    <input type="number" maxLength={10}   required onChange={(e) => handle(e)} id = "number" value={data.number} placeholder='Conatct number' className='rw_footerinputs'></input><br></br>
                    <input type="email" required onChange={(e) => handle(e)} id = "email" value={data.email} placeholder='Your Email' className='rw_footerinputs'></input><br></br>
                    <textarea required onChange={(e) => handle(e)} id = "message" value={data.message} name="" rows="3" cols="" placeholder='Enter your Message' className='rw_footerinputs'>

</textarea>
              <button type='submit' className='rw_footersubbtn'>Submit <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon></button>
                </form>
              </div>
              </div>

              <hr className='fw_hr mt-3 '></hr>
              <div className='col-12 text-center pb-3'>
                <span className='rw_footerinfopara4 '>Designed by <FontAwesomeIcon icon={faCopyright}></FontAwesomeIcon> <a href='https://routeway.in/' className='rw_quicklinks2' target={'_blank'}>Routeway</a></span>
              </div>
            </div>
        </div>
    </div>

   
   </footer>
  )
}
