import { faBars, faChevronDown, faClock, faLocation, faLocationDot, faPhone, faSlash, faUser, faUsers, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../Css/header.css'

export default function Hedar(props) {
 useEffect(() => {
  let nav = document.querySelector(".rw_nav");
  window.onscroll = function() {
      if(document.documentElement.scrollTop > 200){
          nav.classList.add("header-scrolled");
      }else{
          nav.classList.remove("header-scrolled");
      }
  }

  let navlibks = document.querySelectorAll('.rw_navlinks,.rwnavlinkslogin')
  if(window.innerWidth <= 767){
    navlibks.forEach(function(a){
      const navbar = document.querySelector('.rw_navsdatas');
      const xmark = document.querySelector('.rw_barsclose');
      const bars = document.querySelector('.rw_bars');
   
     a.addEventListener("click", function(){  
         navbar.style.display = "none" ;
         xmark.style.display = "none";
         bars.style.display = "block";
     })
    
  
    })
  }

 },[])
 
  const shownavdata = () => {
    const navbar = document.querySelector('.rw_navsdatas');
    const xmark = document.querySelector('.rw_barsclose');
    const bars = document.querySelector('.rw_bars')
   navbar.style.display = "block"
   xmark.style.display = "inline"
   bars.style.display = "none"
  
  }


  const closenavdata = () => {
    const navbar = document.querySelector('.rw_navsdatas');
    const xmark = document.querySelector('.rw_barsclose');
    const bars = document.querySelector('.rw_bars');
    const links = document.querySelector('.rw_navlinks')
   navbar.style.display = "none"
   xmark.style.display = "none"
   bars.style.display = "inline"

   
  }


  

 
  
  


 


  
  return (
    <header className='rw_header'>

      {/* <div className='rw_firstheader'>
        <div className='container'>
          <ul className='rw_firstheaderul'>
            <li><FontAwesomeIcon className='rw_firstheadericons' icon={faLocationDot}></FontAwesomeIcon> Chanakya Marg,Chajjupur
            Shahdara</li>
            <li><FontAwesomeIcon className='rw_firstheadericons' icon={faClock} > </FontAwesomeIcon> Mon - Sat 10.00 - 20.00. Sun CLOSED</li>
            <li><FontAwesomeIcon className='rw_firstheadericons'  icon={faPhone}></FontAwesomeIcon><a href='tel:8434531515' className='rw_firstheadercontact'>+91 8434531515</a></li>
            <li>Delhi</li>
          </ul>
        </div>
      </div> */}

      <div className='rw_nav'>
      <div className='container-fluid'>
      <nav>
        <a href='#' className='rw_logonav'><img src='./images/logo.png' className='img-fluid rw_logonavimglogo'></img>
        <FontAwesomeIcon icon={faBars} className="rw_bars" onClick={shownavdata}></FontAwesomeIcon>
        <FontAwesomeIcon icon={faX} className="rw_barsclose" onClick={closenavdata}></FontAwesomeIcon>
        </a>
        
        <div className='rw_navsdatas'>
        <ul className='rw_navul'>
          <li><NavLink to= '/' className='rw_navlinks'>Home</NavLink></li>
          {/* <li className='bpr_maindropdownnav'><NavLink to= ''  className='rw_navlinks'>Services <FontAwesomeIcon icon={faChevronDown} style={{fontSize : "12px"}}></FontAwesomeIcon></NavLink>
          <div className='rw_navservicedrop'>
            <ul className='bpr_dorpdownul '>
              <li><NavLink to= '' className='rw_secondnavlink'>E-Platform</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Resource Person</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Trading Index</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Profit Matrix</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Logistics Management</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Hassle Management</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Top–Selling Products</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Zero Inventory Manage</NavLink></li>
              <li><NavLink to= '' className='rw_secondnavlink'>Real time Updates</NavLink></li>
            </ul>
          </div></li> */}
          <li><NavLink to= '/Aboutus'  className='rw_navlinks'>About Us</NavLink></li>
          <li><NavLink to= '/Conatact-us'  className='rw_navlinks'>Conatct us</NavLink></li>
          {/* <li><NavLink to= '/Policy'  className='rw_navlinks'>Policy</NavLink></li> */}
         </ul>

         <ul className='rw_navul2'>
          <li><a href={props.loginpath} className='rw_navlinks2'><FontAwesomeIcon className='me-2' icon={faUser} style={{fontSize:"12px"}}></FontAwesomeIcon>Login / Sign-Up</a></li>
         
          {/* <li><p to= '' data-bs-toggle="modal" style={{cursor : "pointer"}} data-bs-target="#exampleModal"  className='rw_navlinks2 rwnavlinkslogin'>Sign Up <FontAwesomeIcon className='ms-2' icon={faUsers} style={{fontSize:"12px"}}></FontAwesomeIcon></p></li> */}
         </ul>
        </div>
       </nav>
      </div>
      </div>

    </header>
  )
}
