import React, { useEffect,useState } from 'react'
import '../Css/contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import Aos from 'aos'
import 'aos/dist/aos'
import 'aos/dist/aos.css'
import { AOS } from 'aos/dist/aos'
import { useLocation } from 'react-router-dom'
import Axios from 'axios';
import Swal from 'sweetalert2';

import { faEye,faBullseye,faFilePen } from '@fortawesome/free-solid-svg-icons'
import { faProductHunt } from '@fortawesome/free-brands-svg-icons'
import { faArrowUpRightDots } from '@fortawesome/free-solid-svg-icons'
import { faPercent } from '@fortawesome/free-solid-svg-icons'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'



export default function Conatctus() {

  const url = "https://app.routeway.in/api/global/enquiry"
  const [data,setdata] = useState({
    name : "",
    email : "",
    number : "",
    state : "",
    wnumber: "",
    query : "",
   
  })

  function handle(e){
  const newdata = {...data}
  newdata[e.target.id] = e.target.value;
  setdata(newdata);
   
  }

  const submit = (e) => {
    e.preventDefault();
  
   Axios.post(url,{
    Name : data.name,
    Email : data.email,
    States : data.state,
    Whatsappno :parseInt(data.wnumber),
    Mobile : parseInt(data.number),
    Query : data.message,
    }).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Thankyou',
        text: 'Thankyou, our concern team will conatct you on your mobile or email',
       
      })
   
    setdata({name : '', email : '', number : '', wnumber : '', state : '', query : ''})
      
  
      
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: err.data.details,
        text: err,
       
      })
      
    })
    
   
    
  }

    const {pathname} = useLocation();
    useEffect(() =>{
     Aos.init({
        duration : 1500,
     })
       
     
       
      window.scrollTo(0,0)
     document.title = "RouteWay conatct-us"
    },[pathname])

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const numericRegex = /^[0-9\b]+$/; // Only allows numeric values and backspace (key code 8)
    
        if (!numericRegex.test(keyValue)) {
          event.preventDefault();
        } 
    }
    
  return (
    <div className='bpr_contactuspage'  style={{overflowX : 'hidden'}}>
    <div className='container'>
    <div className='row'>
        <div className='col-md-6 mt-2'>
        <h5 className="rw_homehjeaderfirst">We bring solutions to make a <span className="rw_homehjeaderfirstspan">e-commerce Business</span></h5>
        </div>
    </div>
        <div className='row bpr_conatcusfirstrow' data-aos= "fade-right">
            <div className='col-md-9 col-12 bpr_conatcusci' id='bpr_getaquote'>
            <h5 className='bpr_contactuspageh5 mb-2'> Fulfil the form and get connected<br></br> with us  <FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon></h5>
             <div className='bpr_formcard'>
             <form onSubmit={(e) => submit(e)}>
              <div className='bpr_formconatcuscon'>
                <input type= "text"  required onChange={(e) => handle(e)} id = "name" value={data.name} placeholder='Your Full Name'  className='bpr_conatctuspageinputs' ></input>
               
                <input type="text"  onKeyPress={handleKeyPress} minLength={10} maxLength={10}  required onChange={(e) => handle(e)} id = "number" value={data.number}  placeholder='Contact number' className='bpr_conatctuspageinputs' ></input>
                <input type="text" onKeyPress={handleKeyPress} minLength={10} maxLength={10}  required onChange={(e) => handle(e)} id = "wnumber" value={data.wnumber}  placeholder='Whatsapp number' className='bpr_conatctuspageinputs' ></input>
                <input type="email" required onChange={(e) => handle(e)} id = "email" value={data.email}   placeholder='Enter Your Email' className='bpr_conatctuspageinputs' ></input>
                <input type= "text"  required onChange={(e) => handle(e)} id = "state" value={data.state} placeholder='Your State Name'  className='bpr_conatctuspageinputs' ></input>
                <textarea required onChange={(e) => handle(e)} id = "message" value={data.message}  rows="3" cols="30" placeholder='Message' className='bpr_conatctuspageinputs'>
                    
                    </textarea>
                    <button type='submit' className='bpr_footerbtnsub6'>  Submit <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon></button>
                </div>
              </form>
             </div>
            </div>

            <div className=' col-md-3 bpr_conatusimagesec '>
                    <img src='./images/conatct.png' className='bpr_contatussimges '></img>
                </div>

                <div className='col-md-5 col-xl-7 ms-auto'>
             <div className="row">
             <div className="col-md-6">
              <h5 className='bpr_weloveimgbotttom'>We’d love to hear from you</h5>
                    <p className='bpr_welovepara'>Whether you have a question about features, trial, pricing, need a demo, or anything else, our team is ready to answer all your question</p>
                    <ul className='rw_constactusulsec'>
                        <li><a href='tel:8434531515'><FontAwesomeIcon icon={faPhone} className='rw_contactivon'></FontAwesomeIcon> 
                        +91 8434531515 </a></li>
                        <li><a href='mailto:info@routeway.in' target={'_blank'}> <FontAwesomeIcon className='rw_contactivon' icon={faEnvelope}></FontAwesomeIcon> info@routeway.in</a></li>
                    </ul>
              </div>
              <div className="col-md-6 mb-2">
              <h5 className='rw_howitworks'>HOW IT WORKS ?</h5>
                           <p className='mt-3 rw_paras'>Here are the 5 working steps of success.</p>
                           <ul className='mt-3 rw_workingstepul'>
                            <li>
                             <h5 className='mb-3'><FontAwesomeIcon icon={faFilePen} className="me-2 rw_cionsworks"></FontAwesomeIcon> Register</h5>
                                <p>Opening account on Routeway is easier than opening a can of soda and it’s FREE! Just integrate your shopify store with our app and you are ready to make money.</p>
                            </li>

                            <li>
                                <h5 className='mb-3'> <FontAwesomeIcon icon={faProductHunt} className="me-2 rw_cionsworks"></FontAwesomeIcon> Product Listing</h5>
                                <p>Add products from our amazing and well maintained catalog to your store in just a few clicks and sync them with our panel!</p>
                            </li>

                            <li>
                                <h5 className='mb-3'><FontAwesomeIcon icon={faArrowUpRightDots} className="me-2 rw_cionsworks"></FontAwesomeIcon> Margin</h5>
                                <p>You can get profit by adding your margin on each product</p>
                            </li>

                            <li>
                                <h5 className='mb-3'> <FontAwesomeIcon icon={faChartPie} className="me-2 rw_cionsworks"></FontAwesomeIcon> Marketing</h5>
                                <p>All over India you can sell your product through Facebook/Instagram and Google also Routeway team will help you.</p>
                            </li>

                            <li>
                                <h5 className='mb-3'> <FontAwesomeIcon icon={faPercent} className="me-2 rw_cionsworks"></FontAwesomeIcon> Profit</h5>
                                <p>We deliver the product and collect cod .and your profit margin is directly deposite in your bank account .</p>
                            </li>
                           </ul>
              </div>

             
             </div>
                </div>


                {/* <div className='col-md-6'>
                <h5 className='bpr_weloveimgbotttom'>We’d love to hear from you</h5>
                    <p className='bpr_welovepara'>Whether you have a question about features, trial, pricing, need a demo, or anything else, our team is ready to answer all your question</p>
                    <ul className='rw_constactusulsec'>
                        <li><a href='tel:7715870353'><FontAwesomeIcon icon={faPhone} className='rw_contactivon'></FontAwesomeIcon> 
                        +91 7715870353 </a></li>
                        <li><a href='mailto:info@routeway.in' target={'_blank'}> <FontAwesomeIcon className='rw_contactivon' icon={faEnvelope}></FontAwesomeIcon> info@routeway.in</a></li>
                    </ul>
                </div> */}
        </div>
    </div>

   
{/* 
    <div className='container mt-4 mb-4'>
        <div className='row' data-aos = "fade-right">
            <div className='col-md-6 col-xl-4'>
            <div className='bpr_cardconatctusbottom'>
            <div className="mapouter"><div className="gmap_canvas" ><iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=bpr network india pvt&t=&z=10&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div>
            <span className='bpr_ourlocationscus'>Our Locations</span>
            <p className='bpr_loactionifo'>click on the map to see our location</p>
            </div>
            </div>     
            
        </div>

        <div className='col-md-6 col-xl-4'>
            <div className='bpr_cardconatctusbottom'>
                <img src='./images/officeimgecon.jpeg' style={{width :"100%", height:"100%", borderRadius:"10px"}}></img>
            </div>
        </div>
        <div className='col-md-6 col-xl-4'>
            <div className='bpr_cardconatctusbottom'>
           <div className='bpr_conatcusaddres'>
            <div className='bpr_haederloca'>contact details</div>
            <ul className='bpr_conatcusul'>
                <li><FontAwesomeIcon icon={faLocationDot} className='bpr_conatcusulicon'></FontAwesomeIcon>Gopi tower 3rd floor Saguna More , Danapur, Patna, Bihar 800012</li>
                <li><FontAwesomeIcon icon={faPhone} className="bpr_conatcusulicon2"></FontAwesomeIcon> <a href='tel:9308478866'> +91 93084 78866 </a></li>
                <li><FontAwesomeIcon icon={faEnvelope} className="bpr_conatcusulicon2"></FontAwesomeIcon> <a href='mailto:office@bpr.net.in'> office@bpr.net.in </a></li>
                
            </ul>
           </div>
            </div>
        </div>
    </div>
    </div> */}

    {/* <div className='bpr_conatctusfooterpage' >
        <div className='row'>
            <div className='col-md-12 col-xl-10'>
                <h4 className='bpr_conatctusfooterpagehead'>Looking for a First-Class Digital Transformation Company?</h4>
            </div>
            <div className='col-xl-2 col-md-6'>
                <button className=' float-right bpr_contuslastbtn' data-bs-toggle="modal" data-bs-target="#exampleModal"> Get a quote <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon> </button>
            </div>
        </div>
    </div> */}
    </div>
  )
}
